import { upperFirst } from 'lodash';

const LOCALE_SEPARATOR = '-';

const getLanguage = (locale: string) => locale.split(LOCALE_SEPARATOR)[0];

const getLanguageDisplayName = (locale: string) => {
  const displayName = new Intl.DisplayNames([locale], { type: 'language' }).of(locale);

  return upperFirst(displayName);
};

export { getLanguage, getLanguageDisplayName };
